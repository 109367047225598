import {toast} from "react-toastify";
import {useDispatch} from "react-redux";
import React, {useEffect, useMemo, useRef, useState} from "react";
import {useLocation, useNavigate} from "react-router-dom";
import Actions from "./Action";
import MoreInfo from "./MoreInfo";
import TitleContent from "./TitleContent";
import ColumnHeaders from "./ColumnHeaders";
import Wrapper from "../../components/Wrapper";
import Breadcrumbs from "../../components/Breadcrumbs";
import {errorHandle} from "../../global/errorHandle";
import {setLoader} from "../../store/slice/dashboard";
import {useLazyGetGlossaryQuery, useUpdateGlossaryMutation} from "../../store/service/adbService";
import "./index.scss";

export default function Glossary() {
    const hasBeenRendered = useRef();
    const location = useLocation();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [tab, setTab] = useState('Titles');
    const [glossary, setGlossary] = useState([]);
    const [glossaryData, setGlossaryData] = useState([]);
    const [subSections, setSubSections] = useState([]);
    const [selectedSubSections, setSelectedSubSections] = useState([]);
    const [selectedValues, setSelectedValues] = useState(null);
    const [getAllGlossaryData, {
        data: updatedGlossaryData,
        error: glossaryError,
        isLoading: glossaryIsLoading,
        isSuccess: glossaryIsSuccess,
        isError: glossaryIsError,
    }] = useLazyGetGlossaryQuery();

    const [updateGlossary, {
        data: updateGlossaryData,
        isError: updateGlossaryIsError,
        isLoading: updateGlossaryIsLoading,
        isSuccess: updateGlossaryIsSuccess,
        error: updateGlossaryError,
    },
    ] = useUpdateGlossaryMutation();

    const handleTab = (tabData) => {
        setTab(tabData);
        setGlossary(tabData.glossary?.[0]);
        setSelectedValues(null);
    };

    const cancelChanges = () => {
        setSelectedValues(null);
    };

    const savedChanges = () => {
        if (selectedValues) {
            const formData = new FormData();
            formData.append('translations', JSON.stringify(selectedValues));
            updateGlossary(formData);
        }
    };

    const middlePathname = decodeURIComponent(
        location.pathname.split('/').slice(-2, -1)[0].replace(/-/g, '/')
    );

    const lastPathname = useMemo(
        () => decodeURIComponent(location.pathname.split('/').pop().replace(/-/g, '-')),
        [location.pathname]
    );

    useEffect(() => {
        const userToken = localStorage.getItem("user_token");
        const glossaryData = localStorage.getItem("glossary");

        if (!userToken) {
            navigate("/login");
        }

        if (!glossaryData) {
            navigate("/manage-glossary");
        } else {
            setGlossaryData(JSON.parse(glossaryData));
        }
    }, [navigate]);

    useEffect(() => {
        if (glossaryData) {
            const pages = glossaryData?.find(page => page.name === middlePathname);
            if (pages && pages.sections) {
                const matchedSection = pages.sections.find(subSection => subSection.name === lastPathname);
                if (matchedSection && matchedSection.subsections) {
                    setSubSections(matchedSection.subsections);
                    setSelectedSubSections(matchedSection.subsections?.[0])
                    setTab(matchedSection.subsections?.[0]?.types?.[0]);
                    setGlossary(matchedSection.subsections?.[0]?.types?.[0].glossary?.[0])
                }
            }
        }
    }, [glossaryData]);

    useEffect(() => {
        if (selectedSubSections &&  hasBeenRendered.current) {
            setTab(selectedSubSections?.types?.[0]);
            setGlossary(selectedSubSections?.types?.[0]?.glossary?.[0]);
        }
        hasBeenRendered.current=true;
    }, [selectedSubSections]);

    useEffect(() => {
        if (glossaryIsSuccess) {
            localStorage.setItem("glossary", JSON.stringify(updatedGlossaryData?.data));
            const pages = updatedGlossaryData?.data?.find(page => page.name === middlePathname);
            if (pages && pages.sections) {
                const matchedSection = pages.sections.find(subSection => subSection.name === lastPathname);

                if (matchedSection && matchedSection.subsections) {
                    const matchedSubsection = matchedSection.subsections.find(subSubSection => subSubSection.name === selectedSubSections.name);
                    setSubSections(matchedSection.subsections);
                    setSelectedSubSections(matchedSubsection);

                    if (matchedSubsection) {
                        const type = matchedSubsection.types.find(type => type.name === tab?.name);
                        setTab(type);

                        if (type) {
                            const newGlossaryData = type.glossary.find(newGlossary => newGlossary.language === glossary.language);
                            setGlossary(newGlossaryData);
                        }
                    }
                }
            }
        }
    }, [glossaryIsSuccess, updatedGlossaryData]);

    useEffect(() => {
        if (updateGlossaryIsSuccess) {
            toast.success(updateGlossaryData?.message, {
                position: toast.POSITION.TOP_RIGHT,
                autoClose: 3000,
            });
            setSelectedValues(null);
            getAllGlossaryData();
        }
    }, [updateGlossaryIsSuccess, updateGlossaryData]);

    useEffect(() => {
        updateGlossaryIsLoading || glossaryIsLoading ?
            dispatch(setLoader(true)) :
            dispatch(setLoader(false));
    }, [updateGlossaryIsLoading, glossaryIsLoading]);

    useEffect(() => {
        if (updateGlossaryIsError) {
            errorHandle(updateGlossaryError);
        }
        if (glossaryIsError) {
            errorHandle(glossaryError);
        }
    }, [updateGlossaryError, updateGlossaryIsError, glossaryError, glossaryIsError]);

    return (
        <Wrapper classname="glossary">
            <Breadcrumbs urlState={subSections}/>
            <div className="glossaryContent">
                <ul className="glossaryContent__subSectionContainer">
                    {subSections?.map(item=>
                        <li
                            key={item.name}
                            onClick={()=> {
                                setSelectedSubSections(item);
                                hasBeenRendered.current=true;
                            }}
                            className={selectedSubSections?.name === item.name ? "glossaryContent__subSectionContainer__activeSection" : ""}
                        >
                            <span />
                            {item.name}

                        </li>
                    )}
                </ul>
                <div className="glossaryContent__glossaryWrapper">
                    <div className="glossary__tabs">
                        {
                            selectedSubSections?.types?.map(item => (
                                <div
                                    key={item.name}
                                    className={`glossary__tab ${tab?.name === item.name ? "glossary__tab_active" : ""}`}
                                    onClick={() => handleTab(item)}
                                >
                                    {item.name === 'Titles' && (
                                        <svg width="20" height="20" viewBox="0 0 20 20" fill="none"
                                             xmlns="http://www.w3.org/2000/svg">
                                            <path fillRule="evenodd" clipRule="evenodd" d="M5.00033 1.66602C3.15938 1.66602 1.66699 3.1584 1.66699 4.99935V14.9993C1.66699 16.8403 3.15938 18.3327 5.00033 18.3327H15.0003C16.8413 18.3327 18.3337 16.8403 18.3337 14.9994V4.99935C18.3337 3.1584 16.8413 1.66602 15.0003 1.66602H5.00033Z"
                                                  stroke="#8A909D" />
                                            <path d="M6.5 5.91699H13.5" stroke="#8A909D" strokeWidth="1.25" strokeLinecap="round"/>
                                            <path d="M10 5.91699V14.0837" stroke="#8A909D" strokeWidth="1.25" strokeLinecap="round"/>
                                        </svg>
                                    )}
                                    {(item.name === 'Headers' || item.name === 'Column Headers') && (
                                        <svg width="18" height="18" viewBox="0 0 18 18" fill="none"
                                             xmlns="http://www.w3.org/2000/svg">
                                            <path fillRule="evenodd" clipRule="evenodd"
                                                  d="M3.99935 0.666016C2.1584 0.666016 0.666016 2.1584 0.666016 3.99935V13.9993C0.666016 15.8403 2.1584 17.3327 3.99935 17.3327H13.9993C15.8403 17.3327 17.3327 15.8403 17.3327 13.9993V3.99935C17.3327 2.1584 15.8403 0.666016 13.9993 0.666016H3.99935ZM6.29102 5.66602C6.29102 5.32084 6.01119 5.04102 5.66602 5.04102C5.32084 5.04102 5.04102 5.32084 5.04102 5.66602V8.99935V12.3327C5.04102 12.6779 5.32084 12.9577 5.66602 12.9577C6.01119 12.9577 6.29102 12.6779 6.29102 12.3327V9.62435H11.7077V12.3327C11.7077 12.6779 11.9875 12.9577 12.3327 12.9577C12.6779 12.9577 12.9577 12.6779 12.9577 12.3327V8.99935V5.66602C12.9577 5.32084 12.6779 5.04102 12.3327 5.04102C11.9875 5.04102 11.7077 5.32084 11.7077 5.66602V8.37435L6.29102 8.37435V5.66602Z"
                                                  stroke="#8A909D"/>
                                        </svg>
                                    )}
                                    {item.name === 'Table Headers' && (
                                        <svg width="16" height="16" viewBox="0 0 16 16" fill="none"
                                             xmlns="http://www.w3.org/2000/svg">
                                            <path fillRule="evenodd" clipRule="evenodd"
                                                  d="M0.566406 3.53979C0.566406 1.89764 1.89764 0.566406 3.53979 0.566406H12.4599C14.1021 0.566406 15.4333 1.89763 15.4333 3.53979V4.46897H5.02648H0.566406V3.53979ZM0.566406 5.58399V12.4599C0.566406 14.1021 1.89764 15.4333 3.53979 15.4333H4.46897V10.2299V5.58399H0.566406ZM5.58399 15.4333H12.4599C14.1021 15.4333 15.4333 14.1021 15.4333 12.4599V10.7874L5.58399 10.7874V15.4333ZM15.4333 9.67239V5.58399L5.58399 5.58399V9.67239L15.4333 9.67239Z"
                                                  stroke="#8A909D"/>
                                        </svg>
                                    )}
                                    {item.name === 'Actions' && (
                                        <svg width="18" height="10" viewBox="0 0 18 10" fill="none"
                                             xmlns="http://www.w3.org/2000/svg">
                                            <path fillRule="evenodd" clipRule="evenodd"
                                                  d="M18 3.77861C18 1.9242 16.5702 0.420898 14.8065 0.420898H3.19355C1.4298 0.420898 0 1.9242 0 3.77861V6.22058C0 8.07499 1.4298 9.57829 3.19355 9.57829L14.8065 9.57829C16.5702 9.57829 18 8.07499 18 6.22058V3.77861ZM9.50941 7.74681C9.19066 7.32875 9 6.79807 9 6.22058V3.77861C9 3.20112 9.19066 2.67044 9.50941 2.25238L3.19355 2.25238C2.39185 2.25238 1.74194 2.93569 1.74194 3.77861L1.74194 6.22058C1.74194 7.06349 2.39185 7.74681 3.19355 7.74681L9.50941 7.74681Z"
                                                  stroke="#8A909D"/>
                                        </svg>

                                    )}
                                    {item.name === 'More Info' && (
                                        <svg width="14" height="14" viewBox="0 0 14 14" fill="none"
                                             xmlns="http://www.w3.org/2000/svg">
                                            <path d="M6.99805 6.33325V9.43992" stroke="#767676" strokeLinecap="round"
                                                  strokeLinejoin="round"/>
                                            <path
                                                d="M6.99805 5.05322C7.27419 5.05322 7.49805 4.82937 7.49805 4.55322C7.49805 4.27708 7.27419 4.05322 6.99805 4.05322C6.7219 4.05322 6.49805 4.27708 6.49805 4.55322C6.49805 4.82937 6.7219 5.05322 6.99805 5.05322Z" fill="#767676"/>
                                            <path d="M6.9987 13.1666C10.4045 13.1666 13.1654 10.4057 13.1654 6.99992C13.1654 3.59416 10.4045 0.833252 6.9987 0.833252C3.59294 0.833252 0.832031 3.59416 0.832031 6.99992C0.832031 10.4057 3.59294 13.1666 6.9987 13.1666Z" stroke="#767676" strokeLinecap="round" strokeLinejoin="round"/>
                                        </svg>
                                    )}
                                    {item.name === 'Placeholder' && (
                                        <svg width="18" height="12" viewBox="0 0 18 12" fill="none"
                                             xmlns="http://www.w3.org/2000/svg">
                                            <path fillRule="evenodd" clipRule="evenodd"
                                                  d="M3.99935 0.166016C2.1584 0.166016 0.666016 1.6584 0.666016 3.49935V8.49935C0.666016 10.3403 2.1584 11.8327 3.99935 11.8327H13.9993C15.8403 11.8327 17.3327 10.3403 17.3327 8.49935V3.49935C17.3327 1.6584 15.8403 0.166016 13.9993 0.166016H3.99935ZM9.41602 5.24935C9.83023 5.24935 10.166 5.58514 10.166 5.99935C10.166 6.41356 9.83023 6.74935 9.41602 6.74935H8.58268C8.16847 6.74935 7.83268 6.41356 7.83268 5.99935C7.83268 5.58514 8.16847 5.24935 8.58268 5.24935H9.41602ZM14.7493 5.99935C14.7493 5.58514 14.4136 5.24935 13.9993 5.24935H13.166C12.7518 5.24935 12.416 5.58514 12.416 5.99935C12.416 6.41356 12.7518 6.74935 13.166 6.74935H13.9993C14.4136 6.74935 14.7493 6.41356 14.7493 5.99935ZM4.83268 5.24935C5.2469 5.24935 5.58268 5.58514 5.58268 5.99935C5.58268 6.41356 5.2469 6.74935 4.83268 6.74935H3.99935C3.58514 6.74935 3.24935 6.41356 3.24935 5.99935C3.24935 5.58514 3.58514 5.24935 3.99935 5.24935H4.83268Z"
                                                  stroke="#8A909D"/>
                                        </svg>
                                    )}
                                    {item.name === 'Tooltips' && (
                                        <svg width="18" height="15" viewBox="0 0 18 15" fill="none"
                                             xmlns="http://www.w3.org/2000/svg">
                                            <path
                                                d="M0.666992 3.49935C0.666992 1.6584 2.15938 0.166016 4.00033 0.166016H14.0003C15.8413 0.166016 17.3337 1.6584 17.3337 3.49935V8.49935C17.3337 10.3403 15.8413 11.8327 14.0003 11.8327H4.00033C2.15938 11.8327 0.666992 10.3403 0.666992 8.49935V3.49935Z"
                                                stroke="#8A909D"/>
                                            <path
                                                d="M9.83546 13.7282L11.0837 11.833H6.91699L8.16519 13.7282C8.56032 14.3281 9.44033 14.3281 9.83546 13.7282Z"
                                                stroke="#8A909D"/>
                                        </svg>

                                    )}
                                    {item.name === 'Hovers' && (
                                        <svg width="20" height="20" viewBox="0 0 20 20" fill="none"
                                             xmlns="http://www.w3.org/2000/svg">
                                            <path
                                                d="M12.8213 11.7653L15.5836 10.06C16.2318 9.65119 16.5808 8.9332 16.4911 8.16535C16.4013 7.40747 15.8928 6.7892 15.1548 6.54987L6.04029 3.5981C5.34224 3.36874 4.58436 3.54824 4.06581 4.06679C3.54726 4.58534 3.36776 5.34323 3.59712 6.04128L6.54886 15.1558C6.78819 15.8938 7.40647 16.4023 8.16436 16.4921C8.24413 16.5021 8.33387 16.502 8.40368 16.502C9.08178 16.502 9.69008 16.163 10.0591 15.5846L11.7643 12.8223L15.1648 16.2228C15.3144 16.3724 15.5038 16.4422 15.6933 16.4422C15.8927 16.4422 16.0822 16.3724 16.2218 16.2228C16.521 15.9336 16.521 15.455 16.2218 15.1658L12.8213 11.7653Z"
                                                stroke="#8A909D"/>
                                        </svg>
                                    )}
                                    {item.name === 'Dropdown' && (
                                        <svg width="14" height="14" viewBox="0 0 14 14" fill="none"
                                             xmlns="http://www.w3.org/2000/svg">
                                            <path
                                                d="M11.6034 0.72168L2.39663 0.72168C0.69222 0.72168 -0.369726 2.57057 0.489075 4.0428L5.09244 11.9343C5.94461 13.3951 8.05538 13.3951 8.90754 11.9343L13.5109 4.0428C14.3697 2.57057 13.3078 0.72168 11.6034 0.72168Z"
                                                stroke="#8A909D"/>
                                        </svg>
                                    )}
                                    {item.name === 'Subjects' && (
                                        <svg width="18" height="16" viewBox="0 0 18 16" fill="none"
                                             xmlns="http://www.w3.org/2000/svg">
                                            <path fillRule="evenodd" clipRule="evenodd"
                                                  d="M8.16602 0.5H9.83268C13.9748 0.5 17.3327 3.85786 17.3327 8C17.3327 12.1421 13.9748 15.5 9.83268 15.5H3.99935C2.1584 15.5 0.666016 14.0076 0.666016 12.1667V8C0.666016 3.85786 4.02388 0.5 8.16602 0.5ZM5.66602 10.2917C5.32084 10.2917 5.04102 10.0118 5.04102 9.66667C5.04102 9.32149 5.32084 9.04167 5.66602 9.04167H8.99935C9.34453 9.04167 9.62435 9.32149 9.62435 9.66667C9.62435 10.0118 9.34453 10.2917 8.99935 10.2917H5.66602ZM5.66602 6.95833C5.32084 6.95833 5.04102 6.67851 5.04102 6.33333C5.04102 5.98816 5.32084 5.70833 5.66602 5.70833H12.3327C12.6779 5.70833 12.9577 5.98816 12.9577 6.33333C12.9577 6.67851 12.6779 6.95833 12.3327 6.95833H5.66602Z"
                                                  stroke="#8A909D"/>
                                        </svg>
                                    )}
                                    {item.name === 'Data' && (
                                        <svg width="14" height="16" viewBox="0 0 14 16" fill="none"
                                             xmlns="http://www.w3.org/2000/svg">
                                            <path fillRule="evenodd" clipRule="evenodd"
                                                  d="M3.20677 0.412109C1.53064 0.412109 0.171875 1.77088 0.171875 3.447V12.5517C0.171875 14.2278 1.53064 15.5866 3.20677 15.5866H10.794C12.4701 15.5866 13.8289 14.2278 13.8289 12.5517V4.7041C13.8289 3.8992 13.5092 3.12726 12.94 2.55811L11.6829 1.30101C11.1138 0.731857 10.3418 0.412109 9.53692 0.412109H3.20677ZM3.96549 3.63669C3.65122 3.63669 3.39645 3.89146 3.39645 4.20573C3.39645 4.52 3.65122 4.77477 3.96549 4.77477H10.0353C10.3496 4.77477 10.6043 4.52 10.6043 4.20573C10.6043 3.89146 10.3496 3.63669 10.0353 3.63669H3.96549ZM3.39645 7.99935C3.39645 7.68508 3.65122 7.43031 3.96549 7.43031H10.0353C10.3496 7.43031 10.6043 7.68508 10.6043 7.99935C10.6043 8.31362 10.3496 8.56839 10.0353 8.56839H3.96549C3.65122 8.56839 3.39645 8.31362 3.39645 7.99935ZM3.96549 11.2239C3.65122 11.2239 3.39645 11.4787 3.39645 11.793C3.39645 12.1072 3.65122 12.362 3.96549 12.362H7.00039C7.31466 12.362 7.56943 12.1072 7.56943 11.793C7.56943 11.4787 7.31466 11.2239 7.00039 11.2239H3.96549Z"
                                                  stroke="#8A909D"/>
                                        </svg>
                                    )}
                                    <span>{item.name}</span>
                                </div>
                            ))
                        }
                    </div>
                    <div className="glossaryContent__glossaryContainer">
                        <div className="glossaryContent__languageSection">
                            {
                                tab?.glossary?.map((item) => (
                                    <div
                                        className={`glossaryContent__languageSection__item ${item.language === glossary.language ? 'glossaryContent__languageSection__item_active' : ''}`}
                                        key={item.language}>
                                        <button onClick={() => setGlossary(item)}>
                                            {item?.image && <img src={item?.image} alt='language logo'/>}
                                            {item.language}
                                        </button>
                                        <span/>
                                    </div>
                                ))
                            }
                        </div>
                        {!['Headers', 'More info', 'Actions'].includes(tab?.name) && (
                            <TitleContent data={glossary} setSelectedValues={setSelectedValues} selectedValues={selectedValues} tabData={tab} hasBeenRendered={hasBeenRendered}/>
                        )}
                        {tab?.name === 'Headers' && <ColumnHeaders data={glossary} setSelectedValues={setSelectedValues} selectedValues={selectedValues} tabData={tab} hasBeenRendered={hasBeenRendered}/>}
                        {tab?.name === 'More info' && <MoreInfo data={glossary} setSelectedValue={setSelectedValues} selectedValues={selectedValues} tabData={tab } hasBeenRendered={hasBeenRendered}/>}
                        {tab?.name === 'Actions' && <Actions data={glossary} setSelectedValues={setSelectedValues} selectedValues={selectedValues} tabData={tab} hasBeenRendered={hasBeenRendered}/>}
                    </div>
                </div>
            </div>
            <div className="glossary__footer">
                <button className="glossary__footer__cancelBtn" onClick={()=>cancelChanges()}>
                    cancel
                </button>
                <button className={`glossary__footer__saveBtn ${selectedValues ? 'glossary__footer__saveBtn_active' : ''}`} onClick={()=>savedChanges()}>
                    save
                </button>
            </div>
        </Wrapper>
    );
}
